
const fontFamilySerifFallback = 'Georgia, SimSun, 宋体, STSong, 华文宋体, serif'
const fontFamilySerifFraunces = `Fraunces, ${fontFamilySerifFallback}`
const fontFamilyCaption = `Lexend Exa, ${fontFamilySerifFraunces}`
const fontWeightRegular = 300
const fontWeightMedium = 350
const fontWeightBold = 400

const bigHeadingStyle = {
  fontFamily: fontFamilySerifFraunces,
  fontWeight: fontWeightBold,
  lineHeight: 1.28
}

const smallHeadingStyle = {
  ...bigHeadingStyle,
  fontWeight: fontWeightBold
}

export default {
  typography: {
    fontFamily: fontFamilySerifFraunces,
    fontFamilyCaption,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: bigHeadingStyle,
    h2: bigHeadingStyle,
    h3: smallHeadingStyle,
    h4: smallHeadingStyle,
    body1: {
      lineHeight: 1.65
    },
    overline: {
      fontFamily: fontFamilyCaption
    },
    button: {
      fontFamily: fontFamilyCaption
    }
  },
  sizing: {
    smGridSize: 20,
    mdGridSize: 24
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2560
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontOpticalSizing: 'auto',
          overflowX: 'hidden',
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamily: fontFamilyCaption,
          fontSize: '0.75rem',
          textTransform: 'uppercase',
        }
      }
    }
  }
}

