import createCache from '@emotion/cache'

const cacheProps = {
  key: 'za',
  prepend: true,
}

let muiCache

export function makeMuiCache() {
  if (!muiCache) {
    muiCache = createCache(cacheProps)
  }

  return muiCache
}
