import { useStaticQuery, graphql } from 'gatsby'

function useSiteMetadata() {
  const { site } = useStaticQuery(graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            author
            siteUrl
            keywords
            fbAppId
            fbAccessToken
          }
        }
      }
    `)
  return site.siteMetadata
}

export default useSiteMetadata
