import { useState, useCallback, useMemo, createContext } from 'react'

import useSSR from '../useSSR'

const AppContext = createContext()

// eslint-disable-next-line react/prop-types
function Wrapper({ children }) {
  const { isBrowser } = useSSR()

  const [colorMode, _setColorMode] = useState((
    isBrowser ? localStorage.getItem('colorMode') : null
  ) || 'dark')

  const setColorMode = useCallback((val) => {
    _setColorMode(val)
    if (isBrowser) {
      localStorage.setItem('colorMode', val)
    }
  }, [isBrowser])

  const context = useMemo(() => ({
    colorMode,
    setColorMode
  }), [colorMode, setColorMode])

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  )
}

export default Wrapper
export { AppContext }
