import { CacheProvider } from '@emotion/react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import grey from '@mui/material/colors/grey'
import common from '@mui/material/colors/common'
import { deepmerge } from '@mui/utils'
import CssBaseline from '@mui/material/CssBaseline'

import { useAppState } from 'hooks'

import { makeMuiCache } from './cache'
import theme from './theme'

const muiCache = makeMuiCache()

const darkTheme = createTheme(deepmerge({
  palette: {
    mode: 'dark',
    background: {
      default: '#151921',
      paper: '#121417',
    }
  }
}, theme))

const lightTheme = createTheme(deepmerge({
  palette: {
    mode: 'light',
    background: {
      default: grey[50],
      paper: common.white,
    },
    text: {
      primary: common.black
    }
  }
}, theme))

function ThemeWrapper({ children }) {
  const { colorMode } = useAppState()

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={colorMode === 'dark' ? darkTheme : lightTheme}>
        {children}
        <CssBaseline />
      </ThemeProvider>
    </CacheProvider>
  )
}

export default ThemeWrapper
