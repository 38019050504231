import { Wrapper } from 'hooks/useAppState'
import { ThemeWrapper } from 'theme'

export const wrapRootElement = ({ element }) => (
  <Wrapper>
    <ThemeWrapper>
      {element}
    </ThemeWrapper>
  </Wrapper>
)
